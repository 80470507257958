import { gql } from "@apollo/client";

export const CHECK_NIF_EXIST = gql`
  query Query($nif: String) {
    CheckNifExist(nif: $nif)
  }
`;

export const FORGOT_NIF = gql`
  query Query($email: String!) {
    forgotNif(email: $email) {
      status
      message
    }
  }
`;

export const VALIDATE_POSTAL_CODE = gql`
  query RootQuery($code: String) {
    getPostalCodeDetails(code: $code) {
      isValid
    }
  }
`;

export const VALIDATE_EMAIL_CODE = gql`
  query validateEmail($email: String!) {
    validateEmail(email: $email) {
      isValid
    }
  }
`;

export const GET_COMPANY_INFO_NIF = gql`
  query getCompanyNifInfo($nif: String!) {
    getCompanyNifInfo(nif: $nif) {
      address
      name
      cae
      postalCode
    }
  }
`;
export const SEND_EMAIL_DOC = gql`
  query Query($id: ID!, $files: [FilesList]) {
    sendProDocsEmail(id: $id, files: $files)
  }
`;
export const GET_PRO_DOCS = gql`
  query GetProDocsEmailContent($id: ID!) {
    getProDocsEmailContent(id: $id) {
      externalId
      to
      from
      bcc
      partnerName
      docs {
        fileUuid
        name
        fileName
        url
      }
    }
  }
`;
export const GET_COMPANY_INFO_NIF_PUBLIC = gql`
  query getCompanyInfoNifService($nif: String!) {
    getCompanyInfoNifService(nif: $nif) {
      address
      cae
      name
      nif
      postalCode
    }
  }
`;

export const GET_CAE = gql`
  query {
    getCae {
      name
      number
    }
  }
`;

export const GET_PROFESSIONS = gql`
  query {
    getProfessions {
      professions
    }
  }
`;

export const PARTNER_USER_APPLICATION_GET = gql`
  query GetPartnerOpenApplications(
    $partnerNif: String!
    $type: JourneyType
    $filters: PartnerApplicationsFilter
  ) {
    getPartnerOpenApplications(
      partnerNif: $partnerNif
      type: $type
      filters: $filters
    ) {
      totalCount
      data {
        licensePlate
        clientReference
        companyId
        companyName
        companyNif
        consents {
          createdAt
          insuranceConsent
          marketingConsent
          privacyConsent
          updatedAt
        }
        createdAt
        customerEmail
        customerName
        customerNif
        customerPhone
        customerReference
        description
        email
        expiresAt
        id
        managerId
        partnerAdminEmail
        partnerName
        partnerNif
        partnershipId
        productName
        status
        totalPrice
        type
        url
        uuidUser
        licensePlate
        startedByCustomer
        triggeredManualRequest
        showManualRequest
      }
    }
  }
`;

export const GET_TRAVEL_JOURNEY = gql`
  query getTravelJourney($id: ID!) {
    getTravelJourney(id: $id) {
      id
      completed
      travelType
      travelReason
      tripEndDate
      tripStartDate
      couponDiscount
      originLocation
      locations
      travelerAge
      travelersQuantity
      otherTravelersAge
      travelValuation
      travelTransports
      connectionFlights
      travelBaggage
      riskActivities
      activitiesEquipments
      email
      travelerName
      companyId
      managerId
      couponCode
      partnershipId
      uuidUser
      expiresAt
    }
  }
`;

export const GET_TRAVEL_JOURNEY_PACKAGES = gql`
  query getTravelJourneyPackages($id: ID!) {
    getTravelJourneyPackages(id: $id, limit: 50) {
      totalCount
      data {
        id
        provider {
          id
          name
          description
          logo
          description
        }
        uuid
        price
        discountPrice
        description
        ourRate
        rate
        travelPackageId
        productName
        recommended
        docPreContractual
        docGeneralConditions
        label
        covidProtection {
          informationLine {
            description
            included
          }
          medicalConsultation {
            description
            included
          }
          medicalAdvice {
            description
            included
          }
          psychologicalCounseling {
            description
            included
          }
          tripCancellation {
            description
            included
          }
          tripInterruption {
            description
            included
          }
          transport {
            description
            included
          }
          paymentMedicalExpenses {
            description
            included
          }
        }
        travelAssistance {
          medicalSurgicalPharmaHospitalExpenses {
            ownCountryExpenses {
              description
              included
            }
            foreignExpenses {
              description
              included
            }
            foreignAccidentExpenses {
              description
              included
            }
          }
          medicalExpenses {
            ownCountryAccidentExpenses {
              description
              included
            }
            woundedSanitaryExpenses {
              description
              included
            }
            woundedRepatriationExpenses {
              description
              included
            }
          }
          hospitalTrackingExpenses {
            transportationExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
          }
          familiarTripAndStayExpenses {
            transportationExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
          }
          extendedStayExpenses {
            transportationExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
          }
          repatriationAfterDeath {
            transportationExpenses {
              description
              included
            }
            urnExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
            advanceFundsExpenses {
              description
              included
            }
            localizationUrgencyDrugsExpenses {
              description
              included
            }
            comunicationPaymentExpenses {
              description
              included
            }
          }
        }
        transport {
          connectionFlightLossExpenses {
            transportationExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
          }
          flightDelayExpensesExpenses {
            transportationExpenses {
              description
              included
            }
            stayExpenses {
              description
              included
            }
          }
        }
        cancelationInteruptionTrip {
          earlyTripCancelationExpenses {
            description
            included
          }
          tripInterruptionExpenses {
            description
            included
          }
        }
        luggage {
          baggageLossTheftDamageExpenses {
            description
            included
          }
          carryPersonalLuggageExpenses {
            description
            included
          }
          carryForgottenObjectExpenses {
            description
            included
          }
          documentsLossTheftExpenses {
            description
            included
          }
          delayOnBaggageReceptionExpenses {
            description
            included
          }
          safesOpenRepairExpenses {
            description
            included
          }
          homeKeysLossExpenses {
            description
            included
          }
        }
        personalAccidents {
          deathOrPermanentDisabilityExpenses {
            description
            included
          }
          temporaryCapacityLossExpenses {
            description
            included
          }
          funeralExpensesExpenses {
            description
            included
          }
        }
        civilResponsability {
          civilResponsabilityExpenses {
            description
            included
          }
        }
        adventure {
          searchRescueOnSkiResortExpenses {
            description
            included
          }
          refundSkiLessons {
            description
            included
          }
          advanceFundsExpenses {
            description
            included
          }
        }
      }
    }
  }
`;

export const GET_PARTNER_CONFIGS = gql`
  query GetPartnerConfigurations($input: PartnerJourneyInput) {
    getPartnerConfigurations(input: $input) {
      logo {
        name
        url
      }
      colors {
        primary
        secondary
        teritiary
      }
      adminContact {
        nif
        personEmail
        personName
        personPhone
      }
      mainContact {
        nif
        personEmail
        personName
        personPhone
      }
      loggedInContact {
        nif
        personEmail
        personName
        personPhone
      }
      uuid
      name
      asfNumber
      authorized
      fontFamily
    }
  }
`;
export const GET_PARTNER_CONFIGS_ADVISOR = gql`
  query getPartnerConfigurationByAdvisorId($userAnswersUuid: String!) {
    getPartnerConfigurationByAdvisorId(userAnswersUuid: $userAnswersUuid) {
      logo {
        name
        url
      }
      colors {
        primary
        secondary
        teritiary
      }
      fontFamily
      name
      asfNumber
      authorized
      commercialContact {
        personPhone
      }
      address
      adminContact {
        personEmail
        nif
        personName
      }
      mainContact {
        nif
        personEmail
        personName
        personPhone
      }
      loggedInContact {
        nif
        personEmail
        personName
        personPhone
      }
    }
  }
`;

export const CHECK_USER_EMAIL_PHONE = gql`
  query getUserByMail($email: String) {
    getUserByMail(email: $email) {
      hasUser
      phone
      email
      birthdate
      name
    }
  }
`;

export const GET_ALL_ADVISOR = gql`
  query GetAllPartnerAdvisor(
    $partnerNif: String!
    $customerNif: String
    $filter: PartnerAdvisorFilter
  ) {
    getAllPartnerAdvisor(
      partnerNif: $partnerNif
      customerNif: $customerNif
      filter: $filter
    ) {
      totalCount
      data {
        url
        createdAt
        birthdate
        customerReference
        submittedAt
        userId
        email
        mandatory {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
        recommended {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
        consider {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
      }
    }
  }
`;

export const GET_PARTNER_CUSTOMER = gql`
  query GetPartnerCustomer($customerId: ID!) {
    getPartnerCustomer(customerId: $customerId) {
      customerId
      customerName
      customerNif
      customerBirthdate
      customerAddress
      customerPostalCode
      customerNationality
      customerEmail
      customerPhone
      customerReference
      customerGender
      customerNotes
      customerType
      customerStatus
      partnerNif
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_ALL_PARTNER_CUSTOMER = gql`
  query GetAllPartnerCustomers(
    $partnerNif: String!
    $filters: PartnerCustomerFilter
  ) {
    getAllPartnerCustomers(partnerNif: $partnerNif, filters: $filters) {
      customerName
      createdAt
      customerEmail
      customerPhone
      customerType
      customerNif
      customerId
      customerBirthdate
      customerGender
      customerNationality
    }
  }
`;

export const GET_PARTNER_CUSTOMER_LIST = gql`
  query GetPartnerCustomersList($partnerNif: String!) {
    getPartnerCustomersList(partnerNif: $partnerNif) {
      customerId
      customerName
      customerNif
    }
  }
`;
export const CHECK_PARTNER_CUSTOMER_NIF = gql`
  query GetPartnerCustomerCheck($partnerNif: String!, $customerNif: String!) {
    getPartnerCustomerCheck(
      partnerNif: $partnerNif
      customerNif: $customerNif
    ) {
      hasUser
      customerName
      customerNif
      customerEmail
      customerId
      customerPhone
      customerBirthdate
    }
  }
`;

export const GET_PARTNER_CUSTOMER_OPENAPP = gql`
  query GetPartnerCustomerOpenApplications(
    $customerNif: String!
    $partnerNif: String!
    $type: JourneyType!
  ) {
    getPartnerCustomerOpenApplications(
      customerNif: $customerNif
      partnerNif: $partnerNif
      type: $type
    ) {
      totalCount
      data {
        id
        customerName
        customerNif
        partnerName
        partnerNif
        type
        totalPrice
        status
        createdAt
        expiresAt
        url
        productName
        clientReference
      }
    }
  }
`;

export const GET_ALL_CUSTOMER_ADVISOR = gql`
  query GetAllPartnerCustomerAdvisor(
    $customerNif: String!
    $partnerNif: String
  ) {
    getAllPartnerCustomerAdvisor(
      customerNif: $customerNif
      partnerNif: $partnerNif
    ) {
      totalCount
      data {
        url
        createdAt
        birthdate
        customerReference
        submittedAt
        userId
        email
        mandatory {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
        recommended {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
        consider {
          car
          bike
          bicycle
          scooter
          home
          content
          mortgage
          life
          housekeeper
          health
          dental
          criticalIllness
          hospitalization
          personalAccident
          sports
          child
          family
          drone
          workAccidentSelfEmployeed
          workAccidentEmployee
          investment
          ppr
          thirdPartyProfessional
          pet
          thirdPartyPet
        }
      }
    }
  }
`;

export const GET_MORTGAGE_ORDER = gql`
  query getMortgageOrder($id: ID!, $isPartnerRequest: Boolean) {
    getMortgageOrder(id: $id, isPartnerRequest: $isPartnerRequest) {
      additionalAttributes {
        bankName
        creditNumber
        creditStartDate
        installments
      }
      uuid
      holder {
        civilStatus
        email
        idNumber
        nationality
        phone
        practiseSport
        profession
        address
        birthdate
        gender
        name
        nif
        postalCode
      }
      journey {
        id
        insuranceMotive
        creditCapital
        holders
        guarantors
        holdersBirthdates
        holdersSmoke
        alreadyHas
        currentPrice
        currentCoverage
        contactNumber
        customerNif
        email
        companyId
        uuidUser
      }
      package {
        death {
          description
          included
        }
        disablement {
          description
          included
        }
        analysis {
          description
          included
        }
        age {
          description
          included
        }
        deathUntil {
          description
          included
        }
        disablementUntil {
          description
          included
        }
        insuranceProviderId
        mortgagePackageId
        productName
        provider {
          id
          address
          description
          logo
          name
          vat
          insuranceCompany
        }
        recommended
        id
        uuid
        price
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
        policyCost
      }
      paymentPeriods {
        description
        type
        value
        methods
      }
      paymentTypes
      uuid
      uuidJourney
      uuidPolicy
      uuidPrice
      uuidPackage

      paymentInfo {
        paymentDocument {
          url
          name
          fileUuid
        }
        iban
        uuid
        orderType
        uuidPolicy
        alias
        certificate
        entity
        extra {
          includesSign
          redirect
          url
        }
        message
        reference
        status
        type
        url
        value
      }

      insurer {
        address
        birthdate
        maritalStatus
        gender
        idNumber
        name
        email
        nif
        phone
        postalCode
        profession
        relation
        sport
        practiseSport
        isInsurerEqual
      }

      insuredPersons {
        address
        birthdate
        civilStatus
        gender
        idNumber
        name
        email
        nif
        phone
        postalCode
        profession
        relation
        sport
        practiseSport
      }
      status
      insuranceStartDate
      beneficiaryType
      beneficiaryPersons {
        address
        birthdate
        civilStatus
        gender
        idNumber
        name
        email
        nif
        phone
        postalCode
        profession
        relation
        sport
        practiseSport
      }
      payment {
        iban
      }
      insuranceStartDate
      formId
    }
  }
`;
export const GET_SUBSCRIPTION = gql`
  query GetPartnerCurrentSubscription($nif: String!) {
    getPartnerCurrentSubscription(nif: $nif) {
      subscriptionPlan
      subscriptionMonthlyValue
    }
  }
`;
export const GET_PROFESSION = gql`
  query {
    getProfessions {
      professions
    }
  }
`;

export const GET_PARTNER_CURRENT_PAYMNET = gql`
  query GetPartnerCurrentPayment($nif: String!) {
    getPartnerCurrentPayment(nif: $nif) {
      ibanMonthlyFee
      ibanCommissionPayment
      isIbanSame
      ibanMonthlyFeePdf {
        name
        url
        fileUuid
      }
      ibanCommissionPaymentPdf {
        name
        url
        fileUuid
      }
    }
  }
`;

export const GET_MORTGAGE_PACKAGES = gql`
  query getMortgageJourneyPackages($id: ID!, $providers: [String]) {
    getMortgageJourneyPackages(id: $id, providers: $providers) {
      data {
        death {
          included
          description
        }
        disablement {
          included
          description
        }
        analysis {
          included
          description
        }
        age {
          included
          description
        }
        deathUntil {
          included
          description
        }
        disablementUntil {
          included
          description
        }
        insuranceProviderId
        mortgagePackageId
        productName
        summaryView {
          death {
            description
            included
          }
          disablement {
            description
            included
          }
          age {
            description
            included
          }
          deathUntil {
            description
            included
          }
          disablementUntil {
            description
            included
          }
        }
        provider {
          id
          address
          description
          logo
          name
          vat
        }
        recommended
        id
        uuid
        price
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
        policyCost
      }
      id
      totalCount
    }
  }
`;
export const CAR_POLICY_GET = gql`
  query CarPartnerPolicyGet($id: ID!) {
    carPartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      isDriverEqual
      isOwnerEqual
      brand
      model
      plateNumber
      paymentType
      docClause
      docGeneralConditions
      docInsuranceCertificate
      docPreContractual
      docFiles {
        name
        url
      }
      file {
        name
        url
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
      }
      paymentInfo {
        paymentDocument {
          url
          name
          fileUuid
        }
      }
      carDriverInfo {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
      }
      carOwnerInfo {
        address
        name
        nif
        phone
        postalCode
        email
        birthdate
      }
    }
  }
`;

export const CAR_COMPANY_POLICY_GET = gql`
  query CarCompaniesPartnerPolicyGet($id: ID!) {
    carCompaniesPartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      isDriverEqual
      isOwnerEqual
      brand
      usage
      professionalUsage
      model
      plateNumber
      paymentType
      docClause
      docGeneralConditions
      docInsuranceCertificate
      docPreContractual
      docFiles {
        name
        url
      }
      file {
        name
        url
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
        profession
        cae
        isInsurerEqual
        hasUsualDriver
      }
      paymentInfo {
        paymentDocument {
          url
          name
          fileUuid
        }
      }
      carDriverInfo {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
      }
      carOwnerInfo {
        address
        name
        nif
        phone
        postalCode
        email
        birthdate
      }
    }
  }
`;

export const WORK_COMPANY_POLICY_GET = gql`
  query WorkAccidentCompaniesPartnerPolicyGet($id: ID!) {
    workAccidentCompaniesPartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      paymentType
      totalAnnualIncome
      employeesCount
      insuredPeople {
        name
        monthlyIncome
        isPartTime
        hourlyIncome
        hoursWeek
        otherIncome
        profession
      }
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      docFiles {
        name
        url
      }
      file {
        name
        url
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
        profession
        cae
        isInsurerEqual
        hasUsualDriver
      }
    }
  }
`;
export const TRAVEL_POLICY_GET = gql`
  query TravelPartnerPolicyGet($id: ID!) {
    travelPartnerPolicyGet(id: $id) {
      endDate
      insuranceSubtype
      travelersNumber
      id
      claimsNumber
      claimsText
      companyId
      createdAt
      externalId
      file {
        fileId
        name
        encodeUrl
        url
        uuid
        fileUuid
        isEditable
      }
      docFiles {
        name
        url
        fileUuid
      }
      paymentInfo {
        entity
        reference
      }
      insuranceBrand
      insuranceType
      managerId
      package
      packageName
      partnershipId
      policyNumber
      premium
      premiumType
      rating
      renewalDate
      renewUrl
      startDate
      status
      type
      uuidUser
      uuidOrder
      email
      policyHolder {
        name
        nif
        address
        postalCode
        cae
        phone
        email
        birthdate
        licenseNumber
        licenseDate
        gender
        profession
        maritalStatus
        isInsurerEqual
        isPassport
        passportExpiration
        passportNumber
      }
      insuredPersons {
        name
        birthdate
      }
      iban
      customerReference
      paymentType
    }
  }
`;
export const BIKE_POLICY_GET = gql`
  query BikePartnerPolicyGet($id: ID!) {
    bikePartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      isDriverEqual
      isOwnerEqual
      brand
      model
      plateNumber
      paymentType
      createdByAdmin
      version
      docFiles {
        name
        url
        fileUuid
      }
      file {
        name
        url
        fileUuid
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
      }
      carDriverInfo {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
      }
      carOwnerInfo {
        address
        name
        nif
        phone
        postalCode
        email
        birthdate
      }
    }
  }
`;
export const HOME_POLICY_GET = gql`
  query HomePartnerPolicyGet($id: ID!) {
    homePartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      homeUsage
      isOwnerEqual
      paymentType
      file {
        name
        url
        fileUuid
      }
      otherInfo {
        hasOtherContact
        address
        postalCode
        hasHomeMortgage
        mortgageInstitution
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        licenseNumber
        birthdate
        cae
        gender
        profession
        maritalStatus
      }
    }
  }
`;

export const WORKACCIDENT_POLICY_GET = gql`
  query WorkPartnerPolicyGet($id: ID!) {
    workAccidentPartnerPolicyGet(id: $id) {
      id
      createdByAdmin
      externalId
      createdByAdmin
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      capital
      paymentType
      file {
        name
        url
        fileUuid
      }
      docFiles {
        name
        url
        fileUuid
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        licenseDate
        profession
        licenseNumber
        birthdate
        cae
      }
    }
  }
`;

export const OTHER_POLICY_GET = gql`
  query OtherPartnerPolicyGet($id: ID!) {
    otherPartnerPolicyGet(id: $id) {
      id
      claimsText
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      paymentType
      file {
        name
        url
        fileUuid
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        birthdate
      }
    }
  }
`;

export const HEALTH_POLICY_GET = gql`
  query HealthPartnerPolicyGet($id: ID!) {
    healthPartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      insuranceBrand
      insuranceSubtype
      status
      type
      iban
      companyId
      email
      insuredPersons
      paymentType
      file {
        name
        url
        fileUuid
      }
      policyHolder {
        address
        name
        nif
        phone
        postalCode
        email
        birthdate
        gender
        profession
        maritalStatus
      }
    }
  }
`;

export const GET_CAR_JOURNEY = gql`
  query getCarJourney($id: ID!) {
    getCarJourney(id: $id) {
      triggeredManualRequest
      showManualRequest
      id
      insuranceMotive
      licensePlate
      brand
      brand2
      plateDate
      category
      fuel
      model
      model2
      identificationType
      driverName
      email
      version
      gender
      version2
      nif
      birthdate
      licenseDate
      isImportedCar
      typeCode
      vehicleType
      postalCode
      carAccident
      knowsRegistration
      noAccidentYears
      lastYearAccidents
      yearlyPay
      insuranceType
      coverages
      startDate
      usage
      name
      professionalUsage
      mainTransport
      carParking
      longTrips
      power
      seats
      customerNif
      companyId
      contactNumber
      isHolderDriver
      holderFullName
      holderNif
      holderBirthdate
      holderDrivingLicenseDate
      holderPostalCode
      holderGender
      uuidUser
    }
  }
`;

export const SUBSCRIBE_CAR_PACKAGES = gql`
  subscription subscribeCarJourneyPackages($id: ID!) {
    subscribeCarJourneyPackages(id: $id) {
      message
      data {
        base {
          civilResponsability {
            description
            included
          }
          glass {
            description
            included
          }
          travelAssistance {
            description
            included
          }
          legalProtection {
            description
            included
          }
          driverProtection {
            description
            included
          }
          peopleTransported {
            description
            included
          }
          theft {
            description
            included
          }
          nature {
            description
            included
          }
          replacementVehicle {
            description
            included
          }
          shockCollisionRollover {
            description
            included
          }
          fireExplosionLightning {
            description
            included
          }
          malicousActs {
            description
            included
          }
        }
        carValue
        policyCost
        insuranceProviderId
        carPackageId
        productName
        provider {
          id
          address
          description
          logo
          name
          vat
        }
        recommended
        id
        uuid
        price
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
      }
      id
      totalCount
    }
  }
`;

export const GET_WORKACCIDENT_SIMULATIONS = gql`
  query GetWorkAccidentSimulations($id: ID!, $providers: [String]) {
    getWorkAccidentSimulations(id: $id, providers: $providers) {
      totalCount
      message
      id
      isSimulating
      simulationStart
      simulationExpired
      data {
        workAccidentId
        uuid
        recommended
        rate
        provider {
          vat
          name
          logo
          insuranceCompany
          id
          description
          address
        }
        productName
        price
        paymentPeriods {
          annual
          monthly
          semestral
          trimestral
        }
        policyCost
        ourRate
        insuranceProviderId
        id
        exclusion {
          exclusion {
            included
            description
          }
        }
        eligibility {
          eligibility {
            activities {
              description
              included
            }
          }
        }
        docPreContractual
        docGeneralConditions
        docClause
        description
        complementaryInfo {
          complementaryInfo {
            included
            description
          }
        }
        baseInfo {
          deathCovarage {
            description
            included
          }
          regulatoryCompliance {
            description
            included
          }
          workAccidentCovarage {
            description
            included
          }
          permanentTotalIncapacity {
            description
            included
          }
        }
      }
    }
  }
`;

export const GET_WORKACCIDENT_COMPANIES_SIMULATIONS = gql`
  query GetWorkAccidentCompaniesSimulations($id: ID!, $providers: [String]) {
    getWorkAccidentCompaniesSimulations(id: $id, providers: $providers) {
      totalCount
      message
      id
      isSimulating
      simulationStart
      simulationExpired
      data {
        id
        uuid
        recommended
        rate
        provider {
          vat
          name
          logo
          insuranceCompany
          id
          description
          address
        }
        packageName
        price
        paymentPeriods {
          annual
          monthly
          semestral
          trimestral
        }
        policyCost
        ourRate
        insuranceProviderId
        id
        exclusion {
          exclusion {
            included
            description
          }
        }
        eligibility {
          eligibility {
            activities {
              description
              included
            }
          }
        }
        docPreContractual
        docGeneralConditions
        docClause
        description
        complementaryInfo {
          complementaryInfo {
            included
            description
          }
        }
        baseInfo {
          deathCovarage {
            description
            included
          }
          regulatoryCompliance {
            description
            included
          }
          workAccidentCovarage {
            description
            included
          }
          permanentTotalIncapacity {
            description
            included
          }
        }
      }
    }
  }
`;

export const GET_WORK_ACCIDENT_JOURNEY = gql`
  query GetWorkAccidentJourney($getWorkAccidentJourneyId: ID!) {
    getWorkAccidentJourney(id: $getWorkAccidentJourneyId) {
      birthdate
      currentPrice
      handlesExplosives
      email
      nif
      id
      companyId
      incapacitated
      incomeMonths
      insuranceMotive
      mealSubsidy
      phoneNumber
      profession
      name
      rawIncome
      requiredTransport
      safetyCompliance
      soleProfession
      workAbroad
      workTravel
      yearlyRevenue
    }
  }
`;

export const GET_CAR_SIMULATIONS = gql`
  query GetCarSimulations($id: ID!, $providers: [String]) {
    getCarSimulations(id: $id, providers: $providers) {
      totalCount
      message
      id
      isSimulating
      simulationStart
      simulationExpired
      data {
        base {
          civilResponsability {
            description
            included
          }
          glass {
            description
            included
          }
          travelAssistance {
            description
            included
          }
          legalProtection {
            description
            included
          }
          driverProtection {
            description
            included
          }
          peopleTransported {
            description
            included
          }
          theft {
            description
            included
          }
          nature {
            description
            included
          }
          replacementVehicle {
            description
            included
          }
          shockCollisionRollover {
            description
            included
          }
          fireExplosionLightning {
            description
            included
          }
          malicousActs {
            description
            included
          }
        }
        carValue
        policyCost
        insuranceProviderId
        carPackageId
        productName
        provider {
          id
          address
          description
          logo
          name
          insuranceCompany
          vat
        }
        recommended
        id
        uuid
        price
        paymentPeriods {
          annual
          monthly
          semestral
          trimestral
        }
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
      }
    }
  }
`;

export const GET_CAR_COMPANY_SIMULATIONS = gql`
  query getCarCompaniesSimulations($id: ID!, $providers: [String]) {
    getCarCompaniesSimulations(id: $id, providers: $providers) {
      totalCount
      message
      id
      isSimulating
      simulationStart
      simulationExpired
      data {
        base {
          civilResponsability {
            description
            included
          }
          glass {
            description
            included
          }
          travelAssistance {
            description
            included
          }
          legalProtection {
            description
            included
          }
          driverProtection {
            description
            included
          }
          peopleTransported {
            description
            included
          }
          theft {
            description
            included
          }
          nature {
            description
            included
          }
          replacementVehicle {
            description
            included
          }
          shockCollisionRollover {
            description
            included
          }
          fireExplosionLightning {
            description
            included
          }
          malicousActs {
            description
            included
          }
        }
        carValue
        policyCost
        insuranceProviderId
        carPackageId
        productName
        provider {
          id
          address
          description
          logo
          name
          insuranceCompany
          vat
        }
        recommended
        id
        uuid
        price
        paymentPeriods {
          annual
          monthly
          semestral
          trimestral
        }
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
      }
    }
  }
`;

export const GET_BIKE_SIMULATIONS = gql`
  query GetBikeSimulations($id: ID!, $providers: [String]) {
    getBikeSimulations(id: $id, providers: $providers) {
      totalCount
      message
      id
      isSimulating
      simulationStart
      simulationExpired
      data {
        base {
          civilResponsability {
            description
            included
          }

          travelAssistance {
            description
            included
          }
          legalProtection {
            description
            included
          }

          peopleTransported {
            description
            included
          }
          shockCollisionRollover {
            description
            included
          }
          theft {
            description
            included
          }
          fireExplosionLightning {
            description
            included
          }
          nature {
            description
            included
          }

          maliciousActs {
            description
            included
          }
          civilUnrest {
            description
            included
          }
          terrorism {
            description
            included
          }
          helmetDamage {
            description
            included
          }
        }
        vehicleValue
        policyCost
        insuranceProviderId
        packageId
        packageName
        provider {
          id
          address
          description
          logo
          name
          vat
          insuranceCompany
        }
        paymentPeriods {
          annual
          monthly
          semestral
          trimestral
        }
        recommended
        id
        uuid

        price
        description
        docClause
        docGeneralConditions
        docPreContractual
        ourRate
        rate
      }
    }
  }
`;

export const GET_DASHBOARD_OPEN_APPS = gql`
  query GetDashboardOpenAppl {
    getDashboardOpenAppl {
      total
      automovel
      casa
      vida
      saude
      outros
    }
  }
`;

export const GET_DASHBOARD_POLICIES = gql`
  query GetDashboardPolicies {
    getDashboardPolicies {
      total
      automovel
      casa
      vida
      saude
      outros
      monthlyCount {
        policyMonth
        policyYear
        count
      }
    }
  }
`;

export const GET_PROVIDERS = gql`
  query getProviders($type: String) {
    getProviders(type: $type) {
      insuranceCompany
    }
  }
`;

export const WARRANTY_POLICY_GET = gql`
  query WarrantyPartnerPolicyGet($id: ID!) {
    warrantyPartnerPolicyGet(id: $id) {
      id
      externalId
      premium
      premiumType
      startDate
      renewalDate
      paymentType
      insuranceBrand
      insuranceSubtype
      status
      type
      companyId
      email
      brand
      model
      iban
      pvp
      paymentType
      purchaseDate
      docFiles {
        name
        url
      }
      file {
        name
        url
      }
      policyHolder {
        address
        name
        surname
        nif
        phone
        postalCode
        email
        birthdate
      }
    }
  }
`;
