// @flow
const titles = {
  entrar: "MUDEY Pro | Entrar",
  registar: "MUDEY Pro | Registar",
  restore: "MUDEY Pro Nova Password",
  configuracoes: "MudeyPRO | Configurações",
  "recuperar-pass": "MUDEY Pro Recuperação Password",
  seguros: "MUDEY Pro Seguros",
  simulações: "MUDEY Pro | Simulações",
  "seguros-details": "MUDEY Pro Detalhe Apólice",
  "cotar-seguros": "MUDEY Pro | Seleção Produto",
  "seguro-carro-empresas-detalhes":"MudeyPRO | Detalhes apólice",
  "recuperar-nif": "MUDEY Pro Recuperação NIF",
  "formulario-adesao": "MUDEY Pro | Formulário Adesão",
  "formulario-assinatura": "MUDEY Pro | Formulário Assinatura",
  "formulario-submetido": "MUDEY Pro | Formulário Submetido",
  "seguro-viagem-detalhes": "MudeyPRO | Seguro Viagem | Detalhes",
  "seguro-vida-credito-detalhes": "MUDEY Pro | Vida Crédito | Detalhes",
  "seguro-carro-detalhes": "MUDEY Pro | Seguro Auto | Detalhes",
  "seguro-moto-detalhes": "MUDEY Pro | Seguro Moto | Detalhes",
  "seguro-acidentes-trabalho-independente-detalhes":
    "MUDEY Pro | Seguro AT | Detalhes",
  "seguro-outro-detalhes": "MUDEY Pro | Seguro Outro | Detalhes",
  "seguro-casa-detalhes": "MUDEY Pro | Seguro Casa | Detalhes",
  "seguro-saúde-detalhes": "MUDEY Pro | Seguro Saúde | Detalhes",
  seguros: "MUDEY Pro | Apólices",
  dados: "MUDEY Pro | Dados",
  clientes: "MudeyPro | Clientes",
  "adicionar-cliente": "MudeyPro | Adicionar Cliente",
  "clientes-detalhes": "MudeyPro | Detalhe Cliente",
  "formulário-plano": "MUDEY Pro | Formulário Plano",
  "configuracoes-marca": "MudeyPro | Módulo Marca",
  dashboard: "MudeyPRO | Dashboard",
  "editar-membro":"MudeyPRO | Editar Membro",
  "add-user":"MudeyPRO | Adicionar Utilizador",
  "register-contact":"MudeyPRO | Registo Novo Utilizador",
  "seguro-viagem": {
    simulacao: "Seguro Viagem | Questionário",
    resultados: {
      summary: "Seguro Viagem | Pagamento",
      comparar: "Seguro Viagem | Comparação",
      pacotes: "Seguro Viagem | Resultados",
    },
    compra: {
      pagamento: "Seguro Viagem | Comparação",
      obrigado: "Seguro Viagem | Comparação",
      beneficiarios: "",
      "thank-you": "Seguro Viagem | Comparação",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
  },

  "seguro-vida-credito": {
    simulacao: "Seguro Vida Crédito | Questionário",
    resultados: {
      summary: "Seguro Vida Crédito | Pagamento",
      comparar: "Seguro Vida Crédito | Comparação",
      pacotes: "Seguro Vida Crédito | Resultados",
    },
    compra: {
      pagamento: "Seguro Vida Crédito | Pagamento",
      obrigado: "Seguro Vida Crédito | Pagamento",
      beneficiarios: "",
      "thank-you": "Seguro Vida Crédito | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
    "calculadora-impacto-spread": {
      questionario:
        "Seguro Vida Crédito | Calculadora - Questionário",
      resultados: "Seguro Vida Crédito | Calculadora - Resultados",
    },
  },
  "seguro-carro-empresas": {
    simulacao: "Seguro Auto Empresas | Questionário",
    resultados: {
      summary: "Seguro Auto Empresas | Pagamento",
      comparar: "Seguro Auto Empresas | Comparação",
      pacotes: "Seguro Auto Empresas | Resultados",
    },
    compra: {
      pagamento: "Seguro Auto Empresas | Pagamento",
      obrigado: "Seguro Auto Empresas | Pagamento",
      beneficiarios: "",
      "thank-you": "Seguro Auto Empresas | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
  },
  "seguro-carro": {
    simulacao: "Seguro Auto | Questionário",
    resultados: {
      summary: "Seguro Auto | Pagamento",
      comparar: "Seguro Auto | Comparação",
      pacotes: "Seguro Auto | Resultados",
    },
    compra: {
      pagamento: "Seguro Auto | Pagamento",
      obrigado: "Seguro Auto | Pagamento",
      beneficiarios: "",
      "thank-you": "Seguro Auto | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
  },
  "seguro-saude": {
    simulacao: "Seguro Saude | Questionário",
    resultados: {
      summary: "Seguro Saude | Pagamento",
      comparar: "Seguro Saude | Comparação",
      pacotes: "Seguro Saude | Resultados",
    },
    compra: {
      pagamento: "Seguro Saude | Pagamento",
      obrigado: "Seguro Saude | Pagamento",
      beneficiarios: "",
      "thank-you": "Seguro Saude | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
  },
  recibos: "MudeyPRO | Recibos",
  "recibos-detalhes": "MudeyPRO | Recibos | Detalhes",
  "seguro-acidentes-trabalho-independente": {
    simulacao: "Seguro AT | Questionário",
    resultados: {
      summary: "Seguro AT | Pagamento",
      comparar: "Seguro AT | Comparação",
      pacotes: "Seguro AT | Resultados",
    },
    compra: {
      pagamento: "Seguro AT | Pagamento",
      obrigado: "Seguro AT | Pagamento",
    },
  },
  "seguro-acidentes-trabalho-outrem": {
    simulacao: "Seguro AT | Questionário",
    resultados: {
      summary: "Seguro AT | Pagamento",
      comparar: "Seguro AT | Comparação",
      pacotes: "Seguro AT | Resultados",
    },
    compra: {
      pagamento: "Seguro AT | Pagamento",
      obrigado: "Seguro AT | Pagamento",
    },
  },
  "seguro-moto": {
    simulacao: "Seguro Moto | Questionário",
    resultados: {
      summary: "Seguro Moto | Pagamento",
      comparar: "Seguro Moto | Comparação",
      pacotes: "Seguro Moto | Resultados",
    },
    compra: {
      pagamento: "Seguro Moto | Pagamento",
      obrigado: "Seguro Moto | Pagamento",
    },
  },
  "venda-cruzada": "Venda Cruzada",
  "venda-cruzada": {
    "": "adsf",
    resultados: "Venda Cruzada | Resultados",
    questionario: "Venda Cruzada | Questionário",
  },
};

export const titleManager = (location: any): void => {
  if (location && location.pathname) {
    const root = location.pathname.split("/")[1] || "";
    const firstKey = location.pathname.split("/")[2] || "";
    const lastKey = location.pathname.split("/")[3] || "";
    const title = () => {
      if (lastKey) {
        return titles[root][firstKey][lastKey] || "MUDEY | Pro";
      }
      // if (lastKey == "" && firstKey == "pessoa-segura-info") {
      //   return "MUDEY Empresas | Seguro Vida Empresas | Info adicional";
      // }
      // if (firstKey == "" && lastKey == "" && root == "parceria-sodexo") {
      //   return "MUDEY Empresas | Sodexo | Parceria";
      // }
      // if (root == "adicionar-seguros" && firstKey == "" && lastKey == "") {
      //   return "MUDEY Empresas | Adicionar Seguros";
      // }
      if (root == "venda-cruzada" && firstKey == "" && lastKey == "") {
        return "MUDEY Pro | Venda Cruzada";
      }
      if (!lastKey && firstKey) {
        return titles[root][firstKey] || null;
      }
      if (root && firstKey.length == 0 && lastKey.length == 0) {
        return titles[root] || null;
      }
      return null;
    };

    const docTitle = title();
    if (docTitle) {
      document.title = docTitle;
    } else {
      document.title = "MUDEY Pro";
    }
  } else {
    document.title = "MUDEY Pro";
  }
};
