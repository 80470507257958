import React, { useCallback, useState, useEffect } from "react";
import RoundTick from "~images/roundGreenTick.png";
import { apiErrorHandler } from "~utils/apiErrorHandler";
import { useLazyQuery } from "@apollo/react-hooks";
import { Spinner } from "~components/spinner";
import { useSelector } from "react-redux";
import { GET_PARTNER_FORMS } from "../../Details/utils/queries";
import "./index.less";

const ThankYouPartnerDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [partnerData, setPartnerData] = useState(null);
  const nif = useSelector((state) => state?.userData?.nif?.split(" ").join(""));

  const [getPartnerDetails] = useLazyQuery(GET_PARTNER_FORMS, {
    fetchPolicy: "no-cache",
    onError: useCallback((error: any) => {
      apiErrorHandler(error);
    }, []),
    onCompleted: useCallback(({ getPartnerDetailsForm }: any) => {
      setPartnerData(getPartnerDetailsForm);
      setIsLoading(false);
    }, []),
  });

  useEffect(() => {
    if (nif) {
      setTimeout(() => {
        getPartnerDetails({
          variables: {
            nif: nif?.replace(/[-\s]/g, ""),
          },
        });
      }, 3000);
    }
  }, []);

  let header, message;
  if (
    partnerData?.adminContact[0]?.personEmail ===
      partnerData?.legalContact[0]?.personEmail ||
    partnerData?.partnershipType === "INDIVIDUAL"
  ) {
    if (
      partnerData?.signatureDeliveryMethod === "URL" &&
      partnerData?.isSignatureComplete
    ) {
      header = "O pedido de acesso à MUDEY Pro foi submetido com sucesso.";
      message =
        "Iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
    } else if (partnerData?.signatureDeliveryMethod === "EMAIL") {
      header = "Documentação enviada para assinatura por email.";
      message =
        "Assim que recebermos os documentos assinados, iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
    }
  } else if (
    partnerData?.adminContact[0]?.personEmail !==
    partnerData?.legalContact[0]?.personEmail
  ) {
    header = "Documentação enviada para assinatura por email.";
    message =
      "Assim que recebermos os documentos assinados, iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
  }

  return (
    <div className="new-thank-you">
      {isLoading && <Spinner />}
      <img src={RoundTick} className="round-tick-img" loading="lazy" />
      {header && message && (
        <>
          {/* <h1 className="heading-text">{header}</h1> */}
          {/* <p className="message-text">{message}</p> */}
          <h1 className="heading-text">
            Pedido de acesso ao período experimental recebido com sucesso.
          </h1>
          <p className="message-text">
            Vamos analisar a documentação que submeteste e respondemos até 2
            dias úteis para o email que indicaste.
          </p>
          {/* <p className="message-text">
            Qualquer questão que tenhas, podes entrar em contacto com a MUDEY
            via chat ou através do email mudeypro@mudey.pt
          </p> */}
        </>
      )}
      <p className="message-text">
        Qualquer questão que tenhas, podes entrar em contacto com a MUDEY via
        chat ou através do email <a href="mailto:mudeypro@mudey.pt">mudeypro@mudey.pt</a>
      </p>
      <p className="message-text">Até já!</p>
      <p className="message-text">MUDEY</p>
    </div>
  );
};

export default ThankYouPartnerDetails;
