import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// const userData = {
//   nif: "204039169",
//   verified: "VERIFIED",
//   personEmail: "pwallet09@yopmail.com",
//   personPhone: "+351 917 838 324",
//   personName: "hjsdf",
//   userId: "41G4K2HPG1Q11XOR63MmhN2Qe2h1",
//   partnerId: "040fb571-c4ab-4e84-9edc-8a448d74a10b",
//   uuid: "152c4699-f80b-4bf6-ac90-3f69e2792d4d",
//   personPosition: "MEMBER",
//   logo: null,
//   colors: null,
//   __typename: "PartnerAdmin",
// };

const useUserRole = () => {
  const [role, setRole] = useState(null);
  const userData = useSelector((state: any) => state?.userData);
  useEffect(() => {
    if (
      userData &&
      ["VIEWER", "MEMBER", "ADMIN"].includes(userData.personPosition)
    ) {
      setRole(userData.personPosition);
    } else {
      setRole(null);
    }
  }, [userData]);

  return {
    isViewer: role === "VIEWER",
    isMember: role === "MEMBER",
    isAdmin: role === "ADMIN",
  };
};

useUserRole.propTypes = {
  userData: PropTypes.shape({
    personPosition: PropTypes.oneOf(["VIEWER", "MEMBER", "ADMIN"]),
  }),
};

export default useUserRole;
